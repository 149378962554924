import React from 'react';
import styled from 'styled-components';
const Button = styled.button`
  cursor: pointer;
  border: none;
  height: 50px;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: bold;
  color: white;
  background: #68c3d4;
  :hover {
    background: #62b7c7;
  }
`;
export default Button;
