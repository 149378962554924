import styled, { css } from 'styled-components';
import { Col, Row } from 'reactstrap';
import React from 'react';
import { View, Text } from '../../components';
import { isDev } from '../../constants';
import { themProp } from '../../utils';
import customers from './customers';
import ReviewSlider from './ReviewSlider';

const Trusted = ({ id }) => (
  <RootView1 id={id} name={id}>
    <RootView noGutters>
      <TitleCol xs={{ size: 10, offset: 1 }} lg={{ size: 4, offset: 0 }}>
        <Header className={'text-center text-lg-left'}>
          Мені <br /> довіряють:
        </Header>
      </TitleCol>
      <Col
        xs={{ size: 12, offset: 0 }}
        sm={{ size: 10, offset: 1 }}
        lg={{ size: 8, offset: 0 }}
      >
        <Row noGutters>
          {customers.map((item, index) => (
            <ImageCol
              xs={{ size: 5, offset: (index % 2) * 2 }}
              lg={{ size: 3, offset: 0 }}
              key={index}
            >
              <Image src={item} className={'image-fluid'} />
            </ImageCol>
          ))}
        </Row>
      </Col>
    </RootView>
    <ReviewSlider />
  </RootView1>
);
export default Trusted;

const ImageCol = styled(Col)`
  position: relative;
  height: 115px;
  width: 200px;
  margin-bottom: 50px;
`;
const RootView1 = styled(View)`
  flex-direction: column;
  @media (min-width: ${themProp('LG')}px) {
    //min-height: 100vh;
  }
`;
const Header = styled(Text).attrs({ as: 'h2' })`
  font-weight: bold;
  font-size: 3rem;
  line-height: 4.25rem;
  @media (min-width: ${themProp('XXL')}px) {
    font-size: 5rem;
    line-height: 6.25rem;
  }
`;
const Image = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: contain;
  // eslint-disable-next-line auto-import/auto-import
  ${isDev &&
    css`
      border: 1px solid red;
    `}
`;
const TitleCol = styled(Col)`
  align-self: center;
  justify-content: center;
  text-align: center;
`;
const RootView = styled(Row)`
  margin-top: 70px;
`;
