export default prop => {
  if (!prop || typeof prop !== 'string') {
    console.error('wrong theme property:', prop);
    return null;
  }
  return props => {
    if (!props.theme) {
      console.error('No theme in context');
      return null;
    }
    const result = props.theme[prop];
    if (typeof result === 'undefined') {
      console.error(`Property ${prop} doesn't exists in theme`);
      return null;
    }
    return result;
  };
};
