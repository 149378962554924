import React from 'react';
import Lottie from 'react-lottie';
import logo_aniation from './logo_aniation';
const LogoAnimated = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: logo_aniation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Lottie
      options={defaultOptions}
      height={58}
      width={200}
      isStopped={false}
      isPaused={false}
    />
  );
};

export default LogoAnimated;
