import styled, { css } from 'styled-components';
import React from 'react';
import { Row, Col } from 'reactstrap';
import { isDev } from '../../../constants';
import themProp from '../../../utils/themProp';
import { Text } from '../../../components';

const Benefit = ({ image, title, description }) => (
  <RootView>
    <Col xs={2}>
      <Image src={image} />
    </Col>
    <ColumnStyled>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </ColumnStyled>
  </RootView>
);
export default Benefit;

const ICON_SIZE = 58;
const RootView = styled(Row)`
  margin-bottom: 40px;
  @media (min-width: ${themProp('MD')}px) {
    margin-bottom: 50px;
  }
  @media (min-width: ${themProp('LG')}px) {
    margin-bottom: 30px;
  }
  @media (min-width: ${themProp('XL')}px) {
    margin-bottom: 35px;
  }
  @media (min-width: ${themProp('XXL')}px) {
    margin-bottom: 50px;
    width: 90%;
  }
`;
const Image = styled.img`
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
  object-fit: contain;
  margin-bottom: 0;
  //margin-right: 44px;
  ${isDev &&
    css`
      border: 1px solid red;
    `}
`;
const Title = styled.h3`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
`;
const Description = styled(Text)`
  font-size: 1rem;
  line-height: 1.37rem;
`;
const ColumnStyled = styled(Col)`
  //justify-content: center;
  ${isDev &&
    css`
      border: 1px solid red;
    `}
`;
