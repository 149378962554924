import useWindowScrollPosition from '@rehooks/window-scroll-position';
import useWindowSize from '@rehooks/window-size';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import themProp from '../../utils/themProp';
const _play = ref =>
  ref.contentWindow.postMessage(
    '{"event":"command","func":"' + 'playVideo' + '","args":""}',
    '*'
  );
const _pause = ref =>
  ref.contentWindow.postMessage(
    '{"event":"command","func":"' + 'pauseVideo' + '","args":""}',
    '*'
  );
const elementInViewport2 = el => {
  let top = el.offsetTop;
  let left = el.offsetLeft;
  let width = el.offsetWidth;
  let height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
};
const Video = () => {
  if (typeof window === 'undefined') {
    global.window = {};
  }
  const vireoRef = useRef(null);
  const wrapperRef = useRef(null);
  const scrollPosition = useWindowScrollPosition();
  useEffect(() => {
    if (!vireoRef.current) return;
    if (elementInViewport2(vireoRef.current)) {
      _play(vireoRef.current);
    } else {
      _pause(vireoRef.current);
    }
  }, [scrollPosition]);
  return (
    <RootView ref={wrapperRef}>
      <Iframe ref={vireoRef} id={'ad'} />
    </RootView>
  );
};
const RootView = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin: 0 5px 30px;
  @media (min-width: ${themProp('LG')}px) {
    margin-left: 85px;
    margin-top: 115px;
    margin-bottom: 115px;
  }
`;
export default Video;
const Iframe = styled.iframe.attrs({
  src:
    'https://www.youtube.com/embed/nf6saRfI32k?rel=0&amp;autoplay=0&enablejsapi=1',
  frameBorder: '0',
  allow:
    'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
  allowFullScreen: true,
})`
  border: 1vmin solid white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
