import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import styled, { css } from 'styled-components';
import { View, Text } from '../../components';
import { isDev } from '../../constants';
import star from './star.svg';
import themProp from '../../utils/themProp';

const ReviewCard = ({ name, date, description, photo }) => (
  <RootView>
    <Row>
      <Col xs={12} lg={{ size: 10, offset: 1 }}>
        <Row>
          <ColStyled
            xs={12}
            md={'auto'}
            className={'no-gutters align-items-center align-items-md-start'}
          >
            <Photo src={photo} className={'mr-0 mr-md-4'} />
          </ColStyled>
          <ColStyled
            xs={12}
            md={'auto'}
            className={'no-gutters align-items-center align-items-md-start'}
          >
            <Name>{name}</Name>
            <Date>{date}</Date>
            <StartRow>
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <Star key={index} />
                ))}
            </StartRow>
          </ColStyled>
        </Row>
        <Description>{description}</Description>
      </Col>
    </Row>
  </RootView>
);
const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export default ReviewCard;
const RootView = styled(Container).attrs({ fluid: true })`
  padding-top: 54px;
  padding-bottom: 54px;

  ${isDev &&
    css`
      border: 1px solid red;
    `}
`;
const Name = styled.div`
  color: white;

  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  @media (min-width: ${themProp('XXL')}px) {
    font-size: 2.25rem;
    line-height: 3rem;
  }
`;

const Date = styled.div`
  color: #ffffff;
  opacity: 0.5;
  font-size: 1rem;
  line-height: 1.375rem;
  @media (min-width: ${themProp('XXL')}px) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`;
const Description = styled(Text)`
  text-wrap: normal;
  margin-top: 22px;
  color: white;
  font-size: 1rem;
  line-height: 1.375rem;
  @media (min-width: ${themProp('XXL')}px) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`;
const Star = styled.img.attrs({ src: star })`
  width: 25px;
  height: 25px;
  margin-right: 6px;
`;
const Photo = styled.img`
  width: 129px;
  height: 129px;
  margin-right: 27px;
`;
const StartRow = styled(View)`
  margin-top: 25px;
`;
