import React from 'react';
import {
  BackgroundContainer,
  ColImage,
  ContainerStyled,
  ImageRow,
  PersonImage,
  RootView,
} from './components';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';

const Contacts = ({ id }) => (
  <RootView id={id} name={id}>
    <ContainerStyled>
      <PhoneForm />
      <EmailForm />
    </ContainerStyled>
    <BackgroundContainer
      fluid
      style={{ position: 'absolute', top: 0, bottom: 0 }}
    >
      <ImageRow>
        <ColImage
          className={'d-none d-md-block'}
          md={{ size: 12, offset: 0 }}
          xl={{ size: 5, offset: 7 }}
        >
          <PersonImage />
        </ColImage>
      </ImageRow>
    </BackgroundContainer>
  </RootView>
);

export default Contacts;
