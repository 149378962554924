import React from 'react';
import styled from 'styled-components';
import CloseButton from '../pagess/VideoFrame/CloseButton';
import MenuColumn from './MenuColumn';
import { UncontrolledCollapse } from 'reactstrap';
import themProp from '../utils/themProp';

const CollapsibleMenu = ({ className }) => (
  <RootView className={className}>
    <CloseButtonStyled className={'d-md-none'} />
    <MenuColumnStyled />
  </RootView>
);
export default CollapsibleMenu;
const RootView = styled(UncontrolledCollapse).attrs({
  navbar: true,
  toggler: '[name=toggler]',
  id: 'nav-menu',
})`
  touch-action: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  background: white;
  transition: width 0.5s;
  //width:0;
  &.show{
  width: 100%;
  }
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  @media (min-width: ${themProp('MD')}px) {
    width: 330px;
  overflow: hidden;
  //&:not(.show) {
  //  width: 0;
  //}
  @media (min-width: ${themProp('MD')}px) {
    display: block !important;
    width: 0px;
    :hover {
      width: 330px;
    }
  }
`;
const MenuColumnStyled = styled(MenuColumn)`
  position: relative;
  mix-blend-mode: normal;
  height: 100%!important;
`;
const CloseButtonStyled = styled(CloseButton).attrs({ name: 'toggler' })`
  opacity: 0.5;
  z-index: 5;
`;
