import styled from 'styled-components';
import themProp from '../utils/themProp';
import Text from './Text';

export default styled(Text).attrs({ as: 'h1' })`
  font-weight: bold;
  color: #424b54;
  font-size: 9.6vw;
  line-height: 12.8vw;
  @media (min-width: ${themProp('SM')}px) {
    font-size: 8.3vw;
    line-height: 11.8vw;
  }
  @media (min-width: ${themProp('MD')}px) {
    font-size: 6.25vw;
    line-height: 8.85vw;
  }
  @media (min-width: ${themProp('LG')}px) {
    font-size: 4vw;
    line-height: 5.6vw;
  }
  // @media (min-width: ${themProp('XL')}px) {
  //   font-size: 4vw;
  //   line-height: 5.67vw;
  // }
  @media (min-width: ${themProp('XXL')}px) {
    font-size: 4.16vw;
    line-height: 5.2vw;
  }
`;
