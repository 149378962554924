import React from 'react';
import styled from 'styled-components';
import { Button } from '../../components';
const RootView = styled(Button)`
  color: white;
  background: #68c3d4;
  :hover {
    background: #62b7c7;
  }
`;
const DetailsButton = props => <RootView {...props}>Детальніше</RootView>;
export default DetailsButton;
