import styled from 'styled-components';
import H1 from './H1';
import Text from './Text';

export default styled(Text)`
  font-weight: bold;
  @media (min-width: 1920px) {
    font-size: 3rem;
    line-height: 4.25rem;
  }
`;
