import React, { useCallback, useRef } from 'react';
import { Row, Col } from 'reactstrap';
import Slider from 'react-slick';

import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import themProp from '../utils/themProp';
const Arrow = styled.img.attrs(props => ({
  src: props.left ? require('./leftArrow.svg') : require('./rightArrow.svg'),
}))`
  width: 24px;
  height: 52px;
  @media (min-width: ${themProp('LG')}px) {
    width: 36px;
    height: 68px;
  }
  margin-right:5px;
  margin-left:5px;
  cursor: pointer;
`;
const SliderStyled = styled(Slider)`
  overflow: hidden;
`;

const ArrowCol = styled(Col)`
  display: flex;
  align-items: center;
`;
const CustomSlider = ({ children, className, settings }) => {
  const ref = useRef(null);
  const goNext = useCallback(() => ref.current && ref.current.slickNext(), []);
  const goPrev = useCallback(() => ref.current && ref.current.slickPrev(), []);
  return (
    <Row className={className} noGutters>
      <ArrowCol
        xs={2}
        lg={1}
        onClick={goPrev}
        className={'justify-content-md-end'}
      >
        <div>
          <Arrow left />
        </div>
      </ArrowCol>
      <Col xs={8} lg={10}>
        <SliderStyled {...settings} className={'gallery-slider'} ref={ref}>
          {children}
        </SliderStyled>
      </Col>
      <ArrowCol
        xs={2}
        lg={1}
        className={'justify-content-end justify-content-md-start'}
        onClick={goNext}
      >
        <div>
          <Arrow rigth />
        </div>
      </ArrowCol>
    </Row>
  );
};
export default CustomSlider;
