import TextField, { HelperText, Input } from '@material/react-text-field';
import React from 'react';
import '@material/react-text-field/dist/text-field.css';
import './fieldStyle.css';
import { View } from '../../components';
import alertIcon from './alertIcon.svg';
import '@material/react-material-icon/dist/material-icon.css';
import styled, { createGlobalStyle, css } from 'styled-components';
import Text from './Text';

const CustomField = ({ input, meta, placeholder, label, className }) => {
  const error =
    meta.touched &&
    (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
    !meta.submitting;
  return (
    <RootView>
      <GlobalStyle />
      <TextFieldStyled
        className={className}
        placeholder={placeholder}
        label={label}
        helperText={error && <ErrorText>Упс, щось пішло не так</ErrorText>}
        trailingIcon={error && <AlertIcon />}
        floatingLabelClassName={'label'}
        lineRippleClassName={'ripple-line'}
        dense
        error={error || ''}
      >
        <Input {...input} placeholder={placeholder} />
      </TextFieldStyled>
    </RootView>
  );
};
export default CustomField;
const RootView = styled(View)``;

const GlobalStyle = createGlobalStyle`
${RootView} .mdc-text-field-helper-line{
bottom: 0
}
`;
const TextFieldStyled = styled(TextField)`
  ${props => (props.error ? css`1px solid red;` : '')};
  height: 50px !important;
  width: 100%;
  border-radius: 0 !important;
  border: 1px solid #cbcbcb !important;
  background-color: white !important;
  input {
    border-bottom: none !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
  }
  div > {
    background: white;
  }
`;
const AlertIcon = styled.img.attrs({ src: alertIcon })`
  width: 20px;
  height: 20px;
  margin: auto;
`;
const ErrorText = styled(Text)`
  color: #eb5757;
  font-size: 12px;
  line-height: 17px;
  bottom: 0;
`;
