import { whyMe1, whyMe2, whyMe3, whyMe4, whyMe5 } from './icons';
export default [
  {
    title: 'Це ваше свято',
    description:
      'Мене ви побачите тільки вчасно,' +
      ' саме там де це потрібно, без зайвої метушні.',
    image: whyMe1,
  },
  {
    title: 'Контраст емоцій',
    description: 'Подарую свято відверто, незабутнє',
    image: whyMe2,
  },
  {
    title: 'План – це основа!',
    description: 'Потрібно скласти деталі і все буде працювати ідеально',
    image: whyMe3,
  },
  {
    title: 'Заряд',
    description:
      'Ви, та Ваші гості – ковток любові,' +
      ' натхнення і фантастичних емоцій! Я віддаю їх Вам десятикратно!',
    image: whyMe4,
  },
  {
    title: 'Зустрінемось?',
    description:
      'Все просто! Я відкритий до спілкування! ' +
      'То як, домовимось про зустріч?',
    image: whyMe5,
  },
];
