import React from 'react';
import styled from 'styled-components';
import { View } from '../../../components';
import H1 from '../../../components/H1';
import Text from '../../../components/Text';

const MainTitle = ({className}) => (
  <RootView className={className}>
    <H1>Ведучий для</H1>
    <Offset>Вашої події</Offset>
    <br />
    <Name> - Юра Корнилюк</Name>
  </RootView>
);
export default MainTitle;

const Name = styled(Text)`
  align-self: flex-end;
  font-size: 1.5rem;
`;

const Offset = styled(H1)`
  text-indent: 2rem;
`;
const RootView = styled(View)`
  flex-direction: column;
  align-self: flex-start;
`;
