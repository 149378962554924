import React from 'react';
import styled from 'styled-components';
import LogoAndPhone from '../../components/LogoAndPhone';
import PersonPhoto from './main-photo.jpg';
import '../../style.css';
import { MainTitle } from './components';
import { Row, Col } from 'reactstrap';
import themProp from '../../utils/themProp';
import View from '../../components/View';
const HomePage = () => (
  <RowStyled noGutters>
    <ColStyled xs={12} lg={{ size: 5, offset: 1 }}>
      <MainTitle className={'align-self-center align-self-lg-start'} />
    </ColStyled>
    <ColStyled xs={{ size: 12, offset: 0 }} lg={{ size: 6, offset: 0 }}>
      <ImageWrapper>
        <Image />
      </ImageWrapper>
    </ColStyled>
  </RowStyled>
);
export default HomePage;

const RowStyled = styled(Row)`
  min-height: calc(100vh - 80px);
  @media (min-width: ${themProp('MD')}px) {
    min-height: 100vh;
  }
`;
const ColStyled = styled(Col)`
  justify-content: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;
const ImageWrapper = styled(View)`
  flex: 1;
  justify-self: stretch;
  margin-bottom: 0px;
  position: relative;
  margin-top: 35px;
  min-height: 100vw;

  @media (min-width: ${themProp('SM')}px) {
    //margin-top: 110px;
    min-height: 50vh;
  }
  @media (min-width: ${themProp('LG')}px) {
    margin-top: 100px;
  }
`;
const Image = styled.img.attrs({ src: PersonPhoto })`
  object-fit: contain;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
  @media (min-width: ${themProp('LG')}px) {
    object-fit: cover;
    object-position: left;
  }
`;
