import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'reactstrap';
import burger from './burger.svg';
import logoSmall from './logoSmall.svg';
import Text from './Text';
import NumberPhone from './NumberPhone';

const phoneNumber = '+38 093 782 36 41';
const MobileHeader = () => (
  <RootView>
    <RowStyled fluid className={'d-md-none'}>
      <Col xs={'auto'}>
        <Icon src={burger} name={'toggler'} />
      </Col>
      <PhoneNumberCol>
        <Text><NumberPhone href='tel:+38 093 728 36 41'>{phoneNumber}</NumberPhone></Text>
      </PhoneNumberCol>
      <Col xs={'auto'}>
        <RightIcon src={logoSmall} />
      </Col>
    </RowStyled>
  </RootView>
);
const Icon = styled.img`
  width: 30px;
  height: 30px;
  :hover {
    cursor: pointer;
  }
  margin: 0;
`;
const PhoneNumberCol = styled(Col)`
  text-align: center;
  font-weight: bold;
`;
const RightIcon = styled(Icon)``;
const RowStyled = styled(Row)`
  background: white;
  height: 80px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 11px;
  padding-right: 15px;
  padding-left: 15px;
`;
const RootView = styled(Container).attrs({ fluid: true })`
  position: sticky;
  top: 0;
  z-index: 3;
`;
export default MobileHeader;
