import { alisha, jara, mariana, olia, olesya, ira, mykola, oleg } from './peoples';
export default [
  {
    name: 'Ярина Полянська',
    date: '27.02.2019',
    photo: jara,
    description:
      'Юра, та я не можу уявити як могло бути без тебе! ' +
      ' Я не переживала ні за що) в нас навіть той дощ був в тєму' +
      '. Гості всі до сьогодні пам’ятають якого ми мали крутого ведучого =)\n' +
      'А моєї мами сестра, як прийшла на весілля і тільки побачила тебе,' +
      ' то зразу сказала, що була вже на весілі де ти був і казала що дуууже ти крутий) ' +
      ' Одним словом, ми раді що ти нам створив таку гарну атмосферу',
  },
  {
    name: 'Оля Яремко',
    date: '23.02.2019',
    photo: olia,
    description:
      'Дякуємо за організацію і проведення нашого свята! ' +
      'Цей день був дуже важливим в нашому житті, ми дуже хвилювались,' +
      ' але завдяки ведучому все було на найвищому рівні, всі гості залишились задоволенні,' +
      ' а ми просто насолоджувались святом =)',
  },
  {
    name: 'Мар’яна Когут',
    date: '15.05.2018',
    photo: mariana,
    description:
      'Юра, дуже дякуємо за теплі слова! Нам надзвичайно приємно!' +
      ' Ще раз дякуємо, що був з нами в цей особливий день!',
  },
  {
    name: 'Алиша Ким',
    date: '01.03.2019',
    photo: alisha,
    description:
      'Спасибо Юре за то, что ты чувствовал каждого гостя, их настроение. За то, что ты праздновал вместе с нами. Каждый конкурс, каждый танец – ты был участником как все. Ты отрывался вместе с нами и это было очень важно для нас. Ты делал настроение. Любой праздник, любой ивент, ты будеш первым кому я позвоню и кого я буду рекомендировать друзьям =)',
  },
  {
    name: 'Олеся Ділай',
    date: '18.07.2020',
    photo: olesya,
    description:
      'Юра, дуже тобі дякуємо! Було дуже круто гості просто в захваті ти талант!!!' +
      'Музика супер проведення весілля, конкурсів супер та все супер!!! Нам все дуже-дуже сподобалося! Своїм дружкам буду тільки тебе рекомендувати, як ведучого! ' +
      'Свято вийшло сімейним і душевним, це те чого ми хотіли! Від мене окрема подяка за пісню та танець з татом.',
  },
  {
    name: 'Іра',
    date: '26.07.2020',
    photo: ira,
    description:
      'Хочемо подякувати за мегакруте свято, що допоміг його зробити таким особливим для нас, веселим і щирим, все було як ми і хотіли ну і особлива подяка за пораду про коротенькі історії про тостуючих, було бомбезно і приємно всім.',
  },
  {
    name: 'Микола Шемота',
    date: '08.08.2020',
    photo: mykola,
    description:
      'Юрій, щиро дякуємо за таке неповторне свято топ-100 твої, ми дуже щасливі що обрали саме тебе, ТИ зробив наше свято надіємося що в тебе ще будуть клієнти з нашого маленького міста Рахів. ' +
      'Успіхів та процвітання в твоїй справі))) Завжди задаволені друзі Коля та Сільвіка.',
  },
  {
    name: 'Олег Флай',
    date: '19.09.2020',
    photo: oleg,
    description:
      'Юра, дякую що зробив нам таке круте свято) ' +
      'Нікапельки не сумнівався, що ти кращий в своїй справі)',
  },
];
