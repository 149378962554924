import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Col, Row } from 'reactstrap';
import {
  ButtonStyled,
  FieldStyled,
  FormStyled,
  H1Styled,
  ImageRow,
  TextAreaStyled,
  emailReg,
} from './components';
import FormSent from './FormSent';
import { api } from '../../utils';

const EmailForm = () => {
  const [isFinished, setIsFinished] = useState(false);
  const submitMessage = values => {
    const errors = {
      email: !emailReg.test(values.email) ? 'error' : '',
      name: !values.name ? 'error' : '',
    };
    if (Object.values(errors).some(_ => _)) {
      return errors;
    }
    setIsFinished(true);
    api.sendContactForm(values);
  };
  return isFinished ? (
    <FormSent onClick={() => setIsFinished(false)} />
  ) : (
    <Form
      onSubmit={submitMessage}
      render={({ handleSubmit }) => (
        <FormStyled onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} sm={6} md={10} lg={9}>
              <H1Styled>Або залиш своє повідомлення</H1Styled>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} xl={3}>
              <Field name={'name'}>
                {props => <FieldStyled {...props} label={'Ім’я'} />}
              </Field>
            </Col>
            <Col xs={12} md={4} lg={5} xl={4}>
              <Field name={'email'}>
                {props => (
                  <FieldStyled {...props} label={'Електронна адреса'} />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8} lg={9} xl={7}>
              <TextAreaStyled
                name={'message'}
                placeholder={
                  'Привіт, дуже подобається як ти працюєш, ми хочемо…'
                }
              />
            </Col>
          </Row>
          <ImageRow>
            <Col
              xs={12}
              md={{ size: 4, offset: 4 }}
              lg={{ size: 3, offset: 6 }}
              xl={{ size: 2, offset: 5 }}
            >
              <ButtonStyled type="submit">Надіслати</ButtonStyled>
            </Col>
          </ImageRow>
        </FormStyled>
      )}
    />
  );
};
export default EmailForm;
