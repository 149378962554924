import React from 'react';
import { Field, Form } from 'react-final-form';
import { Col, Row } from 'reactstrap';
import { ButtonStyled, FieldStyled, PhoneFormStyled } from './components';
import Text from './Text';
import { api } from '../../utils';

const PhoneForm = () => {
  const submitPhone = ({ phone }) => {
    if (!phone || phone.length < 10) {
      return { phone: 'error' };
    }
    api.sendPhoneCallback(phone);
    alert('Надіслано');
  };
  return (
    <Form
      onSubmit={submitPhone}
      render={({ handleSubmit }) => (
        <PhoneFormStyled onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} md={4} lg={4} xl={3}>
              <Field name={'phone'}>
                {props => (
                  <FieldStyled {...props} placeholder={'+38 0ХХ ХХХ ХХ ХХ'} />
                )}
              </Field>
            </Col>
            <Col xs={12} md={4} lg={3} xl={2}>
              <ButtonStyled>Надіслати</ButtonStyled>
            </Col>
          </Row>
          <Row>
            <Col xs={10} sm={12} md={8} lg={7}>
              <Text>
                Я вам зателефоную, і ми узгодимо як влаштувати ваше свято
                незабутнім
              </Text>
            </Col>
          </Row>
        </PhoneFormStyled>
      )}
    />
  );
};
export default PhoneForm;
