import React from 'react';
import styled from 'styled-components';
import closeImage from './closeImage.svg';

const CloseButton = props => <Button {...props} />;
export default CloseButton;
const Button = styled.img.attrs({ src: closeImage })`
  height: 30px;
  width: 30px;
  :hover {
    cursor: pointer;
  }
  position: absolute;
  top: 30px;
  left: 30px;
`;
