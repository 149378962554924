import { Field } from 'react-final-form';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import { Button, View, Text } from '../../components';
import themProp from '../../utils/themProp';
import bgLastPeson from './bg-last-person.jpg';
import CustomField from './CustomField';

export const ColImage = styled(Col)`
  background-size: 523px 768px;
  background-position-x: 100px;
  height: 100%;
`;
export const ImageRow = styled(Row)`
  height: 100%;
`;
export const RootView = styled(View)`
  position: relative;
  overflow: hidden;
  @media (min-width: ${themProp('MD')}px) {
    padding-left: ${themProp('navPadding')}px;
  }
  @media (min-width: ${themProp('LG')}px) {
    padding-left: ${p => +themProp('navPadding')(p) * 1.4}px;
  }
  @media (min-width: ${themProp('XL')}px) {
    padding-left: ${p => +themProp('navPadding')(p) * 2.33}px;
  }
  @media (min-width: ${themProp('XXL')}px) {
    padding-left: ${p => +themProp('navPadding')(p) * 3.08}px;
  }
`;
export const BackgroundContainer = styled(Container)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
`;
export const ContainerStyled = styled(Container).attrs({ fluid: true })`
  padding-top: 72px;
  z-index: 2;
  padding-bottom: 50px;
  width: 100%;
  @media (min-width: ${themProp('XXL')}px) {
    width: 71.4%;
  }
`;

export const PersonImage = styled.img.attrs({ src: bgLastPeson })`
  width: auto;
  margin-bottom: 0;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: -160px;
  @media (min-width: ${themProp('XL')}px) {
    margin-right: auto;
    margin-left: auto;
    right: -100px;
  }
`;
export const ButtonStyled = styled(Button)`
  width: 100%;
  margin-bottom: 30px;
`;
export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
export const PhoneFormStyled = styled(FormStyled)`
  margin-bottom: 28px;
  @media (min-width: ${themProp('SM')}px) {
    margin-bottom: 52px;
  }
  @media (min-width: ${themProp('MD')}px) {
    margin-bottom: 60px;
  }
  @media (min-width: ${themProp('XXL')}px) {
    margin-bottom: 130px;
  }
`;
export const FieldStyled = styled(CustomField)`
  margin-bottom: 20px;
  height: 50px;
`;

export const H1Styled = styled(Text)`
  color: #333333;
  margin-bottom: 30px;
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: bold;
  @media (min-width: ${themProp('XXL')}px) {
    font-size: 3rem;
    line-height: 4.25rem;
  }
`;
export const TextAreaStyled = styled(Field).attrs({ component: 'textarea' })`
  height: 206px;
  padding-top: 15px;
  padding-left: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;
  outline: none;
  border: 1px solid #cbcbcb;
  border-radius: none;
  &:hover {
    background-color:rgba(246, 246, 246, 1);
  }
`;
export const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
