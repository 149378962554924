import styled from 'styled-components';
/*
  Abstract component.Accumulate common style for all typography.
  Dont use directly, but using "as" prop, see styled-components docs.
*/
export default styled.p`
  font-family: Ubuntu;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #424b54;
`;
