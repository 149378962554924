import React from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { ButtonStyled, FieldStyled, H1Styled } from './components';
import Text from './Text';

const FormSent = ({ onClick, className }) => (
  <div className={className}>
    <H1Styled>Надіслано</H1Styled>
    <RootView>
      <Col xs={6}>
        <ThanksText>
          Дякую за Ваше повідомлення! Блищим часом я його неодмінно перегляну.
        </ThanksText>
      </Col>
    </RootView>
    <RootView>
      <Col xs={12} md={4} lg={4} xl={3}>
        <ButtonStyled onClick={onClick}>Надіслати ще</ButtonStyled>
      </Col>
    </RootView>
  </div>
);
export default FormSent;
const RootView = styled(Row)`
  flex-direction: column;
`;

const ThanksText = styled(Text)`
  margin-bottom: 60px;
`;
