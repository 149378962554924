import React, { useEffect, useRef } from 'react';
import { Container } from 'reactstrap';
import styled, { ThemeProvider } from 'styled-components';
import { Column, MobileHeader, MenuColumn, SEO, View } from '../components';
import 'bootstrap/dist/css/bootstrap.min.css';
import CollapsibleMenu from '../components/CollapsibleMenu';
import {
  Trusted,
  Home,
  VideoFrame,
  ImageCarousel,
  Contacts,
  Advantages,
} from '../pagess';
import theme from '../theme';
import { themProp } from '../utils';
console.log('BUILD 2');
const Root = () => {
  useEffect(() => {
    window.location.hash = '';
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <RootView>
        <MobileHeader />
        <OffsetView>
          <SEO />
          <Home />
          <ContainerStyled>
            <Advantages id={'about'} />
          </ContainerStyled>
          <VideoFrame id={'gallery'} />
          <ContainerStyled>
            <ImageCarousel />
            <Trusted id={'customers'} />
          </ContainerStyled>
        </OffsetView>
        <Contacts id={'contacts'} />
        <MenuColumnStyled resizable />
        <CollapsibleMenuStyled />
      </RootView>
    </ThemeProvider>
  );
};
export default React.memo(Root);
const ContainerStyled = styled(Container).attrs({ fluid: true })``;

const CollapsibleMenuStyled = styled(CollapsibleMenu)``;
const MenuColumnStyled = styled(MenuColumn)`
  @media (max-width: ${themProp('XL')}px) {
    &:hover + ${CollapsibleMenuStyled} {
      width: 330px;
    }
  }
`;

const RootView = styled(Column)``;
const OffsetView = styled(Column)`
  @media (min-width: ${themProp('MD')}px) {
    padding-left: ${themProp('navPadding')}px;
  }
  @media (min-width: ${themProp('LG')}px) {
    padding-left: ${p => +themProp('navPadding')(p) * 1.4}px;
  }
  @media (min-width: ${themProp('XL')}px) {
    padding-left: ${p => +themProp('navPadding')(p) * 2.33}px;
  }
  @media (min-width: ${themProp('XXL')}px) {
    padding-left: ${p => +themProp('navPadding')(p) * 3.08}px;
  }
`;
