import styled from 'styled-components';
import React from 'react';
import Column from './Column';
import LogoAnimated from './LogoAnimated';
import logoSmall from './logoSmall.svg';
import phoneIcon from './phoneIcon.svg';
import Text from './Text';
import View from './View';
import NumberPhone from './NumberPhone';

const phoneNumber = '+38 093 728 36 41';
const RootView = styled(Column)`
  align-items: flex-start;
  align-self: center;
  flex-shrink: 0;
`;
const PhoneText = styled(Text)`
  font-size: 1.49rem;
  line-height: 2rem;
  font-weight: bold;
`;
const scrollTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};
const LogoAndPhone = ({ resizable }) => (
  <RootView>
    <ImageWrapper
      onClick={scrollTop}
      className={resizable && 'd-none d-xl-block'}
    >
      <LogoAnimated />
    </ImageWrapper>
    <PhoneText className={resizable && 'd-none d-xl-block'}>
      <NumberPhone href='tel:+38 093 728 36 41'>{phoneNumber}</NumberPhone>
    </PhoneText>
    <Icon src={logoSmall} className={resizable ? 'd-xl-none' : 'd-none'} on />
    <PhoneIcon className={resizable ? 'd-xl-none' : 'd-none'} />
  </RootView >
);
const Icon = styled.img`
  width: 26px;
  height: 29px;
`;
const PhoneIcon = styled.img.attrs({ src: phoneIcon })`
  width: 18px;
  height: 20px;
  position: relative;
  left: 4px;
`;
const ImageWrapper = styled(View)`
  :hover {
    cursor: pointer;
  }
`;
export default LogoAndPhone;
