import useWindowScrollPosition from '@rehooks/window-scroll-position';
import useWindowSize from '@rehooks/window-size';
import { invertObj, map, mergeAll } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Row from './Row';
import View from './View';

const CIRCLE_SIZE = 13;
const COLOR = '#424B54';
const options = [
  { name: 'Про мене', ref: 'about' },
  { name: 'Галерея', ref: 'gallery' },
  { name: 'Мої клієнти', ref: 'customers' },
  { name: 'Контакти', ref: 'contacts' },
];

const changeHash = hash => (window.location.hash = hash);
const hideMenu = () => {
  const el = document.getElementById('nav-menu');
  el && el.classList.remove('show');
};
const NavigationStepper = ({ resizable }) => {
  const value = useCurrentSection(options);
  return (
    <RootView>
      {options.map(({ name, ref }, index, array) => (
        <React.Fragment key={index}>
          <RowStyled>
            <Circle active={value === ref} />
            <Label
              onClick={() => {
                hideMenu();
                return changeHash(ref);
              }}
              active={value === ref}
              className={resizable && 'd-none d-xl-block'}
            >
              {name}
            </Label>
          </RowStyled>
          {index < array.length - 1 && <Line />}
        </React.Fragment>
      ))}
    </RootView>
  );
};
const RootView = styled(View)`
  flex-direction: column;
  //flex-shrink: 0;
  align-self: center;
`;

const Circle = styled(View)`
  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  border-radius: 50%;
  border: 1px solid ${COLOR};
  background: ${p => (p.active ? COLOR : 'transparent')};
`;
const Label = styled.a`
  margin-left: 27px;
  color: ${COLOR};
  font-weight: ${p => (p.active ? 'bold' : 'initial')};
  font-size: 0.75rem;
  word-wrap: normal;
  width: 80px;
`;
const Line = styled(View)`
  border-left: 1px solid;
  height: 74px;
  margin-left: ${CIRCLE_SIZE / 2 - 1}px;
`;
const RowStyled = styled(Row)`
  height: ${CIRCLE_SIZE}px;
  align-items: center;
  :hover {
    cursor: pointer;
    ${Circle} {
      background: ${COLOR};
    }
    ${Label} {
      font-weight: bold;
    }
  }
`;

export default NavigationStepper;
const findPositions = options => {
  const positions = map(({ ref }) => {
    const el = typeof document !== 'undefined' && document.getElementById(ref);
    return { [ref]: el && el.offsetTop };
  }, options);
  return mergeAll([...positions, { start: 0 }]);
};
const useGetInitFlag = () => {
  const [initFlag, init] = useState(false);
  useEffect(() => {
    setTimeout(() => init(true), 1000);
  }, []);
  return initFlag;
};

const getCurrent = (positions, scrollPosition) => {
  const targetNumbers = map(
    val => Math.floor(Math.abs(scrollPosition.y - val)),
    positions
  );
  const number = Math.min(...Object.values(targetNumbers));
  return invertObj(targetNumbers)[number];
};
const useCurrentSection = options => {
  if (typeof window === 'undefined') {
    global.window = {};
  }
  const initFlag = useGetInitFlag();
  const scrollPosition = useWindowScrollPosition({ throttle: 300 });
  const windowSize = useWindowSize();
  const positions = useMemo(() => findPositions(options), [
    windowSize,
    initFlag,
  ]);
  return getCurrent(positions, scrollPosition);
};
