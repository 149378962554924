import React from 'react';
import { Col, Row } from 'reactstrap';
import styled, { css } from 'styled-components';
import H1 from '../../components/H1';
import { isDev } from '../../constants';
import { Benefit, Motto, Delight } from './components';
import benefitsData from './benefitsData';
import View from '../../components/View';
import themProp from '../../utils/themProp';

const renderBenefit = (item, index) => <Benefit {...item} key={index} />;
const AdvantagesPage = ({ id }) => (
  <RootView>
    <RowStyled id={id} name={id}>
      <Col xl={{ size: 12, offset: 0 }} style={{ textAlign: 'center' }}>
        <HeaderStyled>Чому я?</HeaderStyled>
      </Col>
    </RowStyled>
    <RowStyled>
      <ColStyled xs={12} sm={8} md={10} lg={6}>
        {benefitsData.map(renderBenefit)}
      </ColStyled>
      <ColStyled xs={12} lg={{ size: 5, offset: 1 }}>
        <Motto />
        <Delight />
      </ColStyled>
    </RowStyled>
  </RootView>
);
const ColStyled = styled(Col)`
  flex-direction: column;
`;
const RowStyled = styled(Row)`
  //flex: 1;
  //padding-right: 0;
  //justify-content: center;
  ${isDev &&
    css`
      border: 1px solid blue;
    `}
`;
export default AdvantagesPage;
const HeaderStyled = styled(H1)`
  margin-bottom: 46px;
`;
const RootView = styled(View)`
  margin-top: 80px;
  flex-direction: column;
  margin-bottom:110px;
  @media (min-width: ${themProp('LG')}px) {
    //min-height: 100vh;
  }
`;
