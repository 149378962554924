export const apiURL = 'http://yurakornyliuk.com/api';

const fetch = ({ url, body }) =>
  window.fetch(`${apiURL}/${url}`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
const sendContactForm = body => fetch({ url: 'contact-form', body });
const sendPhoneCallback = phone => fetch({ url: 'callback', body: { phone } });
export default { sendContactForm, sendPhoneCallback };
