import React, { useCallback, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import { H2 } from '../../components';
import PhoneNumberModal from './PhoneNumberModal';
import Video from './Video';
import DetailsButton from './DetailsButton';
import Text from '../../components/Text';
import themProp from '../../utils/themProp';
import View from '../../components/View';

const VideoFrame = ({ id }) => {
  const [phoneModalVisible, setPhoneModalVisible] = useState(false);
  const showPhoneModal = useCallback(() => setPhoneModalVisible(true), [
    phoneModalVisible,
  ]);
  const hidePhoneModal = useCallback(() => setPhoneModalVisible(false), [
    phoneModalVisible,
  ]);
  return (
    <BackgroundView style={{ background: '#424B54' }} id={id} name={id}>
      <ContainerStyled fluid>
        <RootView>
          <Col
            lg={{ size: 7, offset: 0, order: 1 }}
            xs={{ size: 12, order: 2 }}
          >
            <Video />
          </Col>
          <DescriptionCol
            xs={{ size: 12, order: 1 }}
            lg={{ size: 3, offset: 1, order: 2 }}
            className={'align-items-lg-start'}
          >
            <Title className={'text-lg-left'}>
              Ведучий <br />
              для твого свята
            </Title>
            <ButtonStyled onClick={showPhoneModal} />
          </DescriptionCol>
        </RootView>
      </ContainerStyled>
      <PhoneNumberModal toggle={hidePhoneModal} isOpen={phoneModalVisible} />
    </BackgroundView>
  );
};
export default VideoFrame;

const BackgroundView = styled(View)`
  background: #424b54;
  opacity: 0.9;
  margin-bottom: 40px;
  @media (min-width: ${themProp('LG')}px) {
    margin-bottom: 64px;
  }
  @media (min-width: ${themProp('XXL')}px) {
    margin-bottom: 100px;
  }
`;
const ButtonStyled = styled(DetailsButton)`
  width: 220px;
  margin-bottom: 100px;
  margin-top: 20px;
`;
const ContainerStyled = styled(Container).attrs({ fluid: true })``;
const RootView = styled(Row).attrs({ noGutters: true })`
  align-items: center;
  align-self: center;
  justify-self: center;
`;

const Title = styled(Text).attrs({ as: 'h2' })`
  color: white;
  font-size: 2.25rem;
  line-height: 3rem;
  text-align: center;
  margin-top: 80px;
  font-weight: bold;
  @media (min-width: ${themProp('XXL')}px) {
    font-size: 3rem;
    line-height: 4.25rem;
  }
`;
const DescriptionCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
