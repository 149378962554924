import artfam from './artfam.svg';
import avalon from './avalon.svg';
import lexus from './lexus.svg';
import malevich from './malevich.svg';
import muesliMania from './muesli-mania.svg';
import princess from './princess.svg';
import roksolana from './roksolana.svg';
import shine from './shine.svg';
import carbazar from './carbazar.svg';
import popcorntest from './popcorntest.svg';
import rumbambar from './rumbambar.svg';
import spartak from './spartak.svg';

export default [
  avalon,
  princess,
  muesliMania,
  lexus,
  artfam,
  malevich,
  shine,
  roksolana,
  carbazar,
  popcorntest,
  rumbambar,
  spartak,
];
