import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import themProp from '../../../utils/themProp';
import { Text } from '../../../components';
const Delight = () => (
  <RootView>
    <ColStyled
      xs={{ size: 12, offset: 0 }}
      md={{ size: 6, offset: 3 }}
      lg={{ size: 12, offset: 0 }}
    >
      <DescriptionText>{descriptionContent}</DescriptionText>
      <EmailText href={`mailto:${email}`} target="_blank">
        {email}
      </EmailText>
    </ColStyled>
  </RootView>
);
export default Delight;
const ColStyled = styled(Col)`
  padding-left: auto;
  padding-right: auto;
  @media (min-width: ${themProp('LG')}px) {
    padding-left: 29px !important;
    padding-right: 23px !important;
  }
  @media (min-width: ${themProp('XL')}px) {
    padding-left: 40px !important;
    padding-right: 60px !important;
  }
  @media (min-width: ${themProp('XXL')}px) {
    padding-left: 50px !important;
    padding-right: 40px !important;
  }
`;
const RootView = styled(Row)`
  padding-top: 60px;
  padding-bottom: 50px;
  background: #68c3d4;
`;
const DescriptionText = styled(Text)`
  color: white;

  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 52px;
  @media (min-width: ${themProp('XXL')}px) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`;
const EmailText = styled.a`
  color: white;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  text-decoration: none;
  :hover {
    text-decoration: none;
    cursor: pointer;
    color: white;
  }
  // @media (min-width: ${themProp('LG')}px) {
  //   font-size: 1rem;
  //   line-height: 1.375rem;
  // }
  @media (min-width: ${themProp('XXL')}px) {
    font-size: 2.25rem;
    line-height: 3rem;
  }
`;

const email = 'yurakornyliuk@gmail.com';
const descriptionContent =
  'Займатись тим, що подобається, настільки цікаво, що стає непомітно як за плечима пролітає багаторічний досвід роботи,  десятки щасливих подружніх пар та тисячі заряджених моєю енергією людей';
