import React from 'react';
import styled, { css } from 'styled-components';
import { isDev } from '../constants';
import Column from './Column';
import LogoAndPhone from './LogoAndPhone';
import NavigationStepper from './NavigationStepper';
import Row from './Row';
import SocialButtons from './SocialButtons';

const MenuColumn = props => (
  <RootView
    {...props}
    className={
      `${
        props.resizable ? 'd-none' : 'd-flex'
      } d-md-flex align-items-center align-items-xl-start` + props.className
    }
    // name={props.resizable && 'toggler'}
  >
    <LogoAndPhone resizable={props.resizable} />
    <NavigationStepper resizable={props.resizable} />
    <SocialButtons resizable={props.resizable} />
  </RootView>
);
export default MenuColumn;

const RootView = styled(Column)`
  z-index: 3;
  justify-content: space-around;
  align-self: stretch;
  opacity: 0.7;
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  padding-left: 27px;
  max-height: 1000px;
  min-height: 500px;
  color: #424b54;
  ${isDev &&
    css`
      border: 1px solid red;
    `}
`;
