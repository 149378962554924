import React from 'react';
import styled from 'styled-components';
import Row from '../Row';
import youtubeImage from './youtube.svg';
import instagramImage from './instagram.svg';
import facebookImage from './facebook.svg';
import commonImage from './common.svg';
const SIZE = 40;

const SocialButtons = ({ resizable, className }) => (
  <>
    <RootView
      className={resizable ? `d-none d-xl-flex ${className}` : className}
    >
      {data.map((item, index) => (
        <Button {...item} key={index} />
      ))}
    </RootView>
    <RootView
      className={resizable ? `d-xl-none ${className}` : `d-none ${className}`}
    >
      <Button image={commonImage} />
    </RootView>
  </>
);
export default SocialButtons;
const Image = styled.img`
  width: ${SIZE}px;
  height: ${SIZE}px;
`;
const RootView = styled(Row)`
  justify-content: center;
`;

const data = [
  { url: 'https://www.facebook.com/pg/kornyliuk/posts', image: facebookImage },
  { url: 'https://www.instagram.com/kornyliuk/?hl=uk', image: instagramImage },
  {
    url: 'https://www.youtube.com/channel/UCQMX01yfMUBaH68Oju7iw8w',
    image: youtubeImage,
  },
];
const Link = styled.a`
  margin-right: 20px;
  :last-child {
    margin-right: 0px;
  }
`;
const Button = ({ image, url = '#' }) => (
  <Link href={url} target="_blank">
    <Image src={image} fill={'black'} />
  </Link>
);
