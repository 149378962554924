import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import { Col, Container, Modal, Row } from 'reactstrap';
import CustomField from '../Contacts/CustomField';
import { Text } from '../../components';
import CloseButton from './CloseButton';
import DetailsButton from './DetailsButton';
import coffeeIcon from './coffeeIcon.svg';
import themProp from '../../utils/themProp';
import { api } from '../../utils';
const useSubmitPhone = onSubmit =>
  useCallback(
    ({ phone }) => {
      if (!phone || phone.length < 10) {
        return { phone: 'error' };
      }
      api.sendPhoneCallback(phone).catch(e => console.log(e));
      onSubmit();
    },
    [onSubmit]
  );
const PhoneNumberModal = ({ isOpen = true, toggle }) => {
  const [isFinished, setFinish] = useState(false);
  const finish = useCallback(() => {
    setFinish(true);
    setTimeout(toggle, 2000);
  }, [isFinished]);
  const resetFinish = useCallback(() => setFinish(false), []);
  const onSubmit = useSubmitPhone(finish);
  return (
    <ModalStyled
      isOpen={isOpen}
      toggle={toggle}
      centered
      size={'lg'}
      onOpened={resetFinish}
    >
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <FormStyled onSubmit={handleSubmit}>
            <Row>
              <div>
                <CloseButton onClick={toggle} />
              </div>
              <Col
                xs={{ size: 12 }}
                md={{ size: 10, offset: 1 }}
                className={'align-items-stretch'}
              >
                <Row>
                  <Col xs={{ size: 6, offset: 3 }}>
                    <Image className={'img-fluid'} />
                  </Col>
                </Row>
                <Description>
                  {isFinished ? (
                    <>Дякую що залишили Ваш номер. Ближчим часом я вас наберу</>
                  ) : (
                    <>
                      Залиште свій номер телефону,
                      <br /> і я при першій змозі Вам зателефоную
                    </>
                  )}
                </Description>
                {!isFinished && (
                  <>
                    <Row>
                      <Col
                        xs={{ size: 12, offset: 0 }}
                        md={{ size: 6, offset: 3 }}
                      >
                        <Field name={'phone'}>
                          {props => (
                            <FieldStyled
                              {...props}
                              placeholder={'+38 0ХХ ХХХ ХХ ХХ'}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={{ size: 12, offset: 0 }}
                        md={{ size: 6, offset: 3 }}
                      >
                        <ButtonStyled>Детальніше</ButtonStyled>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </FormStyled>
        )}
      />
    </ModalStyled>
  );
};
export default PhoneNumberModal;
const Image = styled.img.attrs({ src: coffeeIcon })`
  margin-bottom: 70px;
`;
const Description = styled(Text)`
  text-align: center;
  margin-bottom: 20px;
  font-size: 1rem;
  line-height: 1.375rem;

  @media (min-width: ${themProp('MD')}px) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`;
const FieldStyled = styled(CustomField)`
  margin-bottom: 20px;
  height: 50px;
  border: 1px solid #cbcbcb;
`;
const FormStyled = styled(Container).attrs({ tag: 'form', fluid: true })`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 10vh;
  padding-bottom: 5vh;
  border: 10px solid #424b54;
  border-radius: 10px;
  height: 60%;
`;
const ButtonStyled = styled(DetailsButton)`
  width: 100%;
  @media (min-width: ${themProp('XXL')}px) {
    width: 50%;
    margin-left: 25%;
  }
`;
const ModalStyled = styled(Modal)`
  border: 3px solid red;

  &,
  .modal-content {
    border-radius: 10px;
    border: none;
  }
`;


//TODO opacity .8
