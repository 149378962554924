import React, { useRef } from 'react';
import styled from 'styled-components';
import { CustomSlider } from '../../components';
import images from './images';
import Slider from 'react-slick';
import themProp from '../../utils/themProp';
const settings1 = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToScroll: 1,
  variableWidth: true,
  // centerMode: true,
  arrows: false,
  useTransform: true,
  lazyLoad: false,
  adaptiveHeight: true,
  autoplay: true,
  autoplaySpeed: 3000,
};
const settings2 = {
  lazyLoad: false,
  adaptiveHeight: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  useTransform: false,
  swipe: false,
};
const ImageCarousel = () => {
  const bigRef = useRef(null);
  return (
    <>
      <BigSlider {...settings2} ref={bigRef}>
        {images.map((item, index) => (
          <ImageWrapper key={index}>
            <Image src={item} />
          </ImageWrapper>
        ))}
      </BigSlider>
      <CustomSliderStyled settings={{ ...settings1, asNavFor: bigRef.current }}>
        {images.map((item, index) => (
          <ImageWrapper key={index}>
            <Image src={item} />
          </ImageWrapper>
        ))}
      </CustomSliderStyled>
    </>
  );
};
export default ImageCarousel;
const Image = styled.img`
  width: auto;
`;
const CustomSliderStyled = styled(CustomSlider)`
  ${Image} {
    height: 51px;
    @media (min-width: ${themProp('MD')}px) {
      height: 93px;
    }
    @media (min-width: ${themProp('LG')}px) {
      height: 200px;
    }
  }
`;
const ImageWrapper = styled.div`
  margin-right: 10px;
`;
const BigSlider = styled(Slider)`
  width: calc(100%);
  overflow-x: hidden;
  margin-bottom: 40px;
  ${Image} {
    margin-right: auto;
    margin-left: auto;
    max-height: 581px;
    @media (min-width: ${themProp('LG')}px) {
      max-height: 397px;
    }
  }
  @media (min-width: ${themProp('LG')}px) {
    display: none !important;
  }
  ${ImageWrapper} {
    margin: 0px;
    position: relative;
    left: -1px;
    right: 2px;
  }
`;
