import React from 'react';
import { CustomSlider } from '../../components';
import ReviewCard from './ReviewCard';
import styled from 'styled-components';
import reviewData from './reviewData';
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  useTransform: true,
};
const SliderStyled = styled(CustomSlider)`
  background: #68c3d4;
  margin-top: 100px;
  margin-bottom: 100px;
`;
const ReviewSlider = () => (
  <SliderStyled {...settings}>
    {reviewData.map((item, index) => (
      <div key={index}>
        <ReviewCard {...item} />
      </div>
    ))}
  </SliderStyled>
);
export default ReviewSlider;
