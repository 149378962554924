import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import React from 'react';
import quotesImage from './quotes.svg';
import { Text } from '../../../components';
import themProp from '../../../utils/themProp';

const Motto = () => (
  <RootView>
    <ColStyled
      xs={{ size: 10, offset: 2 }}
      md={{ size: 6, offset: 3 }}
      lg={{ size: 12, offset: 0 }}
    >
      <Content>
        <LeftQuotesCol />
        <RightQuotesCol style={{ transform: 'scale(-1, -1)' }} />
        Влаштую свято <br />
        будь-де, будь-коли,
        <br />
        та за будь-яких умов
      </Content>
    </ColStyled>
  </RootView>
);
export default Motto;
const ColStyled = styled(Col)`
  padding-left: auto;
  padding-right: auto;
  @media (min-width: ${themProp('LG')}px) {
    padding-left: 29px !important;
    padding-right: 23px !important;
  }
  @media (min-width: ${themProp('XL')}px) {
    padding-left: 40px !important;
    padding-right: 60px !important;
  }
  @media (min-width: ${themProp('XXL')}px) {
    padding-left: 50px !important;
    padding-right: 40px !important;
  }
`;
const RootView = styled(Row)`
  justify-content: flex-start;
  position: relative;
  flex-shrink: 0;
  flex: 1;
  margin-bottom: 100px;
`;
const Content = styled(Text)`
  display: inline-block;
  position: relative;
  font-size: 1.5rem;
  line-height: 2rem;
  width: auto;
  font-weight: bold;
  margin-top: 50px;
  z-index: 1;
  color: #424b54;
  @media (min-width: ${themProp('XXL')}px) {
    font-size: 2.25rem;
    line-height: 3rem;
  }
`;
const Image = styled.img.attrs({ src: quotesImage })`
  width: 126px;
  position: absolute !important;
  z-index: -1;
  padding: 0;
`;
const LeftQuotesCol = styled(Image)`
  top: -40px;
  left: -40px;
`;
const RightQuotesCol = styled(Image)`
  right: -30px;
  bottom: -75px;
  transform: scale(-1, -1);
  @media (min-width: ${themProp('SM')}px) {
    right: -35px;
  }
  @media (min-width: ${themProp('LG')}px) {
    right: -50px;
    bottom: -75px;
  }
  @media (min-width: ${themProp('XXL')}px) {
    bottom: -60px;
  }
`;
